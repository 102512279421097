import React, { useState } from "react";
import Modal from "react-responsive-modal";
import { addMessageAction } from "../helpers";

const AddMessage = ({ card, actions }) => {
  const [open, setOpen] = useState(false);

  const [message, setInputs] = useState({
    cardId: card.id,
    contact: "",
    from: "",
    to: "",
    message: ""
  });

  const checkIfdisable = ({ from, to, message }) => {
    return isNullOrEmpty(from) || isNullOrEmpty(to) || isNullOrEmpty(message);
  };

  const handleSubmit = event => {
    if (event) event.preventDefault();
    addMessageAction({ message, actions });
    setOpen(false);
  };
  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };

  const disable = checkIfdisable(message);
  return (
    <div>
      <button className="main_section_2__button" onClick={() => setOpen(true)}>
        Add Note
      </button>
      <Modal
        styles={{
          modal: {
            borderRadius: "8px"
          }
        }}
        open={open}
        onClose={() => setOpen(false)}
        center
      >
        <p>Add a note</p>
        <div className="center add_message__input">
          <input
            name="from"
            onChange={handleInputChange}
            placeholder="From"
          ></input>
          <input
            name="contact"
            onChange={handleInputChange}
            placeholder="your phone number (Optional)"
          ></input>
          <input
            name="to"
            onChange={handleInputChange}
            placeholder="To"
          ></input>
          <textarea
            name="message"
            onChange={handleInputChange}
            placeholder="Add a thank you note"
          ></textarea>
          <button
            disabled={disable}
            className={`add_message__button ${disable ? "disabled" : ""}`}
            onClick={handleSubmit}
          >
            Done
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AddMessage;

const isNullOrEmpty = function(value) {
  return !(typeof value === "string" && value.length > 0);
};
