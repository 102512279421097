import React, { useEffect, useRef } from "react";
import L from "leaflet";

function Map({ locations }) {
  // create map
  const mapRef = useRef(null);

  useEffect(() => {
    mapRef.current = L.map("map", {
      center: [12.9716, 77.5946],
      zoomControl: false,
      attributionControl: false,
      zoom: 14,
      // scrollWheelZoom: false,
      layers: [
        L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        })
      ]
    });
  }, []);

  useEffect(() => {
    let activeMarker;
    const activeIcon = L.icon({
      iconUrl: require("../assets/images/marker_active.svg"),
      iconSize: [25, 50] // size of the icon
    });
    const diableIcon = L.icon({
      iconUrl: require("../assets/images/marker_disable.svg"),
      iconSize: [25, 50] // size of the icon
    });

    let markers = [];

    const arrayOfLatLngs = locations
      .sort((a, b) => a.timestamp - b.timestamp)
      .map((location, id) => {
        const latlog = [location.latitude, location.longitude];
        if (location.current) {
          activeMarker = L.marker(latlog, {
            icon: activeIcon
          })
            .bindPopup("You")
            .addTo(mapRef.current);
        } else {
          const marker = L.marker(latlog, {
            icon: diableIcon
          })
            .bindPopup(`${id + 1}`)
            .addTo(mapRef.current);
          markers.push(marker);
        }
        return latlog;
      });

    if (activeMarker) {
      activeMarker.openPopup();
    }
    if (markers.length > 0) {
      markers.forEach(m => m.openPopup());
    }

    if (arrayOfLatLngs.length === 1) {
      mapRef.current.setView(arrayOfLatLngs[0], 14);
    } else if (arrayOfLatLngs.length >= 2) {
      const bounds = new L.LatLngBounds(arrayOfLatLngs);
      mapRef.current.fitBounds(bounds);
    } else {
      mapRef.current.setView([13.0000049, 77.5535354], 14);
    }
  }, [locations]);

  return <div id="map" />;
}

export default Map;
