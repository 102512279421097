import React, { Fragment } from "react";
import logo from "../assets/images/logo.svg";
import { useHistory } from "react-router-dom";

export default function Welcome() {
  let history = useHistory();
  return (
    <Fragment>
      <img className="welcome__logo" alt="logo" src={logo}></img>
      <div className="welcome__text">
        <p>Please enter the number</p>
        <p>mentioned below the QR code</p>
      </div>
      <input
        className="welcome__input"
        type="number"
        min="1"
        max="1000"
        onKeyPress={e => {
          if (e.key === "Enter") {
            history.push(`/${e.target.value}`);
          }
        }}
      ></input>
    </Fragment>
  );
}
