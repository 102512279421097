import {
  addUser,
  updateCardMessageId,
  addMessage,
  updateCardHopCount,
  updateGlobalHopCount
} from "./../helpers/db";

export const addMessageAction = async ({ message, actions }) => {
  const messageId = await addMessage({
    ...message,
    timestamp: new Date().getTime()
  });
  updateCardMessageId({ id: message.cardId, messageId }).then(() => {
    actions.setIsNoteAdded(true);
    alert("Message successfuly saved");
  });
};

export const getLocation = (cardId, actions) => {
  if ("geolocation" in navigator) {
    const geo_options = {
      enableHighAccuracy: true,
      maximumAge: 30000,
      timeout: 27000
    };
    navigator.geolocation.getCurrentPosition(
      async position => {
        const user = {
          cardId,
          location: {
            longitude: position.coords.longitude,
            latitude: position.coords.latitude
          },
          timestamp: new Date().getTime()
        };
        const id = await addUser(user);
        actions.setUserId(id);
        actions.setUsers(users => [...users, { ...user, id, current: true }]);
      },
      positionError,
      geo_options
    );
  } else {
    console.log("Geolocation is not supported");
  }
};

const positionError = error => {
  if (error.PERMISSION_DENIED) {
    alert("Geolocation is not enabled. Please enable to use this site");
  }
};

export const getGetOrdinal = number => {
  const s = ["th", "st", "nd", "rd"],
    v = number % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
};

export const updateHopCount = (cardId, actions) => {
  updateCardHopCount(cardId);
  updateGlobalHopCount();
  actions.setCard(card => ({
    ...card,

    hopCount: card.hopCount + 1
  }));
  actions.setGlobalState(globalState => ({
    ...globalState,
    hopCount: globalState.hopCount + 1
  }));
};
