import React, { Fragment } from "react";
import Modal from "react-responsive-modal";
import thank_you from "../assets/images/thank_you.svg";

const ViewMessage = ({ message, actions }) => {
  const [open, setOpen] = React.useState(true);
  return (
    <Fragment>
      {/* <button
        onClick={() => setOpen(true)}
        className="main_section_1_message__button"
      >
        {`View Message from ${message.from}`}
      </button> */}
      <Modal
        styles={{
          modal: {
            borderRadius: "8px"
          }
        }}
        open={open}
        onClose={() => {
          setOpen(false);
          actions.setIsMessageShowed(true);
        }}
        center
      >
        <div className="center">
          <img alt="thank_you" src={thank_you} />
          <h2>{message.to}</h2>
          <p className="view_message__message">{message.message}</p>
          <p>~{message.from}</p>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ViewMessage;

// Object.entries(message).length === 0 && message.constructor === Object:false;
