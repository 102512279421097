import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import main_image_1 from "../assets/images/main_image_1.svg";
import main_image_2 from "../assets/images/main_image_2.svg";
import main_image_3 from "../assets/images/main_image_3.svg";
import main_image_4 from "../assets/images/main_image_4.svg";
import logo from "../assets/images/logo.svg";
import android from "../assets/images/android.svg";
import apple from "../assets/images/apple.svg";
import twitter from "../assets/images/twitter.svg";
import Map from "./Map";

import ViewMessage from "./ViewMessage";
import AddMessage from "./AddMessage";

import { useData } from "./../hooks";
import { getLocation, getGetOrdinal, updateHopCount } from "./../helpers";

export default function Main() {
  let { id } = useParams();
  const {
    error,
    loading,
    card,
    message,
    globalState,
    users,
    visited,
    isNoteAdded,
    isMessageShowed,
    userId,
    actions
  } = useData(id);

  console.log("[main] ", {
    error,
    loading,
    card,
    message,
    globalState,
    users,
    visited,
    isNoteAdded,
    isMessageShowed,
    userId,
    actions
  });

  if (loading) {
    return <Loading />;
  } else if (!error && !loading && card.id) {
    if (visited) {
      if (userId === "") {
        console.log("[main] Getting location");
        getLocation(card.id, actions);
      }
    } else {
      updateHopCount(card.id, actions);
      actions.setVisited(true);
    }

    return (
      <Fragment>
        <Section1
          card={card}
          message={message}
          users={users}
          userId={userId}
          isMessageShowed={isMessageShowed}
          actions={actions}
        />
        <Section2 card={card} actions={actions} isNoteAdded={isNoteAdded} />
        <Section3 globalState={globalState} />
        <Section4 />
      </Fragment>
    );
  } else {
    return <h4>{`Card not found ${id}`}</h4>;
  }
}

const Loading = () => {
  return <div className="loader"></div>;
};

const Section1 = ({
  card,
  message,
  users,
  userId,
  isMessageShowed,
  actions
}) => {
  const locations = users.map(user =>
    user.id === userId
      ? { ...user.location, timestamp: user.timestamp, current: true }
      : { ...user.location, timestamp: user.timestamp, current: false }
  );
  const flag = message && !isMessageShowed;

  return (
    <section className="main_section_1">
      <div>
        <div className="main_section_1__text">
          <p>You are</p>
          <p className="number">
            {card.hopCount}
            <sub>{getGetOrdinal(card.hopCount)}</sub>
          </p>
          <p>link of the biggest</p>
          <p>human chain in kindness!</p>
        </div>
        <img
          className="main_section_1__image"
          alt="main_1"
          src={main_image_1}
        />
      </div>
      <Map locations={locations} />
      {flag && <ViewMessage message={message} actions={actions} />}
    </section>
  );
};

const Section2 = ({ card, isNoteAdded, actions }) => (
  <section className="main_section_2">
    <span>It’s your turn to share the card</span>
    <img
      className="main_section_2__image"
      alt="main_section_2__image"
      src={main_image_2}
    />
    <p>with someone who has been kind to you.</p>
    <p>If you wish you can add a supporting note.</p>
    {!isNoteAdded && <AddMessage card={card} actions={actions} />}
  </section>
);

const Section3 = ({ globalState }) => (
  <section className="main_section_3">
    <div className="center_row">
      <div className="center">
        <img alt="main_3" src={main_image_3} />
        <span className="number">{globalState.cards}</span>
        <p>Total number of </p>
        <p>cards distributed</p>
      </div>
      <div className="center">
        <img alt="main_4" src={main_image_4} />
        <span className="number">{globalState.hopCount}</span>
        <p>Number of times</p>
        <p>card forwarded</p>
      </div>
    </div>
  </section>
);

const Section4 = () => (
  <section className="main_section_4">
    <p>Want to be more Joyful? Try the Black Lotus app</p>
    <div className="center_row">
      <a href="https://iamblacklotus.com/">
        <img className="main_section_4__logo" alt="logos" src={logo} />
      </a>
      <a href="https://apps.apple.com/us/app/black-lotus/id1225659190">
        <img className="main_section_4__social" alt="apple" src={apple} />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.rt.pinprickeffect.meditation&hl=en">
        <img className="main_section_4__social" alt="android" src={android} />
      </a>
      <a href="https://twitter.com/bl_peace">
        <img
          className="main_section_4__social_twitter"
          alt="twitter"
          src={twitter}
        />
      </a>
    </div>
  </section>
);
