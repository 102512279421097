import { useState, useEffect } from "react";
import { getCard, getGlobalState, getMessage, getUsers } from "./../helpers/db";
export const useData = id => {
  // initialize our default state
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [card, setCard] = useState(null);
  const [message, setMessage] = useState(null);
  const [globalState, setGlobalState] = useState(null);
  const [users, setUsers] = useState(null);
  const [visited, setVisited] = useLocalStorage("visited", false);
  const [isMessageShowed, setIsMessageShowed] = useLocalStorage(
    "isMessageShowed",
    false
  );
  const [isNoteAdded, setIsNoteAdded] = useLocalStorage("isNoteAdded", false);
  const [userId, setUserId] = useLocalStorage("userId", "");

  useEffect(() => {
    const getData = async () => {
      try {
        const card = await getCard(id);
        setCard(card);

        const globalState = await getGlobalState();
        setGlobalState(globalState);

        if (
          card.messageId &&
          (card.messageId !== "" || card.messageId !== "0")
        ) {
          const message = await getMessage(card.messageId);
          setMessage(message);
        }

        const users = await getUsers(id);
        setUsers(users);
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [id]);

  return {
    error,
    loading,
    card,
    message,
    globalState,
    users,
    visited,
    isMessageShowed,
    isNoteAdded,
    userId,
    actions: {
      setCard,
      setUsers,
      setUserId,
      setVisited,
      setIsNoteAdded,
      setGlobalState,
      setIsMessageShowed
    }
  };
};

export const useLocalStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log("[useLocalStorage]", error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
};
