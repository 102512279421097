import React, { Fragment } from "react";
import "./assets/css/app.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "./components/Footer";
import Main from "./components/Main";
import Welcome from "./components/Welcome";

function App() {
  return (
    <Fragment>
      <div className="wireframe__main">
        <Router>
          <Switch>
            <Route exact path="/" component={Welcome} />
            <Route exact path="/:id" component={Main} />
          </Switch>
        </Router>
      </div>
      <Footer />
    </Fragment>
  );
}
// <Route path="/:id" component={User} />

export default App;
