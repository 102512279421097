import firebase, { firestore } from "../firebase";

const db = firebase.firestore();
const increment = firestore.FieldValue.increment(1);

const COLLECTIONS = {
  USERS: "users",
  CARDS: "cards",
  GLOBAL: "global",
  MESSAGES: "messages"
};

export const addMessage = async message => {
  const newMessageRef = await db.collection(COLLECTIONS.MESSAGES).doc();
  const messageId = newMessageRef.id;
  await newMessageRef.set({
    ...message,
    id: messageId
  });
  return messageId;
};

export const addUser = async user => {
  const newUserRef = await db.collection(COLLECTIONS.USERS).doc();
  const newUserId = newUserRef.id;
  await newUserRef.set({
    ...user,
    id: newUserId
  });
  return newUserId;
};

export const updateCardMessageId = async ({ id, messageId }) => {
  const cardRef = db.collection(COLLECTIONS.CARDS).doc(id);
  return await cardRef.update({
    messageId
  });
};

export const updateCardHopCount = async id => {
  const cardRef = db.collection(COLLECTIONS.CARDS).doc(id);
  return await cardRef.update({
    messageId: "0",
    hopCount: increment
  });
};

export const updateGlobalHopCount = async () => {
  const gsRef = db.collection(COLLECTIONS.GLOBAL).doc("main");
  return await gsRef.update({
    hopCount: increment
  });
};

export const getCard = async id => {
  const cardRef = await db
    .collection(COLLECTIONS.CARDS)
    .doc(id)
    .get();
  return cardRef.data();
};

export const getGlobalState = async () => {
  const gsRef = await db
    .collection(COLLECTIONS.GLOBAL)
    .doc("main")
    .get();
  return gsRef.data();
};

export const getMessage = async id => {
  const messageRef = await db
    .collection(COLLECTIONS.MESSAGES)
    .doc(id)
    .get();
  return messageRef.data();
};

export const getUsers = async cardId => {
  const usersRef = await db
    .collection(COLLECTIONS.USERS)
    .where("cardId", "==", cardId)
    .get();

  return usersRef.docs.map(doc => doc.data());
};
